
export const projects = [
    {
        title: "book app",
        description: "Basic CRUD developed with MERN stack, deployed on nginx server in Docker container",
        img: require('../assets/bookApp.png'),
        url: "http://ruthlessai.com/"
    },
    {
        title: "Ethereum tx aggregator",
        description: "Aggregates txs from multiple blockchains/L2s. Backend build with fastapi, frontend vite React, deployed on nginx server in Docker container",
        img: require('../assets/txAggreg.png'),
        url: "http://164.92.138.48:5175/"
    },
    {
        title: "cooming soon",
        description: "stay tuned!",
        img: require('../assets/comingsoon.jpeg'),
        url: ""
    }
]