import React from 'react';
import { Link } from 'react-scroll';
// import Logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className='py-8'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <Link to='/' duration={200} smooth={true}>
            {/* <img className='' src={Logo} alt='' /> */}
            <p className='text-4xl font-bold hover:cursor-pointer'>gapro8</p>
          </Link>  
          <Link to='contact' duration={200} smooth={true}>
            <button  className='btn btn-sm'>work with me</button>
          </Link>
        </div>
      </div>
    </header>

  );
};

export default Header;
