import React from 'react'
import ProjectTile from './ProjectTile.js'

const ProjectGrid = ({projects}) => {
    return (
        <div className='flex-1 lg:grid grid-cols-3 gap-x-4'>
            {projects.map((project) => (
                <ProjectTile title={project.title} description={project.description} img={project.img} url={project.url} />
            ))}
        </div>
    )
}

export default ProjectGrid