import React from 'react';


const ProjectTile = (props) => {
  return (
    <div className='group basis-1/3 relative overflow-hidden border-2 mb-4 border-white/30 rounded-xl cursor-pointer'>
      <a href={props.url}>
        <div className='group-hover:bg-black/60 w-full h-full absolute transition-all duration-300'></div>
        <img className='opacity-80' src={props.img} alt='' />
        <div className='p-2'>
        <h3 id='title' className='font-bold'>{props.title}</h3>
        <p id='description'>{props.description}</p>
        </div>
      </a>
    </div>
  );
}

export default ProjectTile