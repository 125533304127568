import React from 'react';
import Image from '../assets/myAvatar.svg';
import { FaGithub } from 'react-icons/fa';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-scroll';


const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78] flex items-center' id='home'>
        <div className='container mx-auto'>
          <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
            <div className='flex-1 text-center font-secondary lg:text-left'>
              <h1 className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>Gašper - <span>gapro8</span></h1>
              <div className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
                <span className='text-white/80 mr-4'>I am a</span>
                <TypeAnimation sequence={['Programmer', 2000, 'Explorer', 2000, 'Developer', 2000]}
                speed={50} className='text-accent' wrapper='span' repeat={Infinity} />
              </div>
                <p className='mb-8 max-w-lg mx-auto lg:mx-0'>
                  Meet me with problems and I will say goodbye to you with solutions! I mainly do software engineering, but I am interested in all kinds of problems. Don't hesitate and send me a message. Let's talk, let's meet, let's have a good time.
                </p>
                <div className='flex max-w-max gap-x-6 items-center mb-12'>
                  <Link to='contact' duration={200} smooth={true}>
                    <button className='btn btn-lg'>contact me</button>
                  </Link>
                  <Link to='work' className='text-gradient btn-link hover:cursor-pointer' duration={200} smooth={true}>
                    my portfolio
                  </Link>
                  <p className='text-gradient'>/</p>
                  <a href={process.env.REACT_APP_GITHUB_URL} className=''><FaGithub /></a>
                </div>
            </div>
            <div className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] opacity-80'>
              <img src={Image} alt='' />
            </div>
          </div>
        </div>
    </section>
  );
};

export default Banner;
