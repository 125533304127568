import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'



const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        enqueueSnackbar('message sent!', { variant: 'success' });

      }, (error) => {
        console.log(error.text);
        enqueueSnackbar('error', { variant: 'error' });
      });
  };
  return (
    <section id='contact' className='py-16 lg:section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          <div className='flex-1'>
            <div>
              <h4 className='text-xl uppercase text-accent font-mdeium mb-2 tracking-wide'>Get in touch</h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                let's work <br /> together!
              </h2>
            </div>
          </div>
          <form ref={form} onSubmit={sendEmail} className='flex-1 flex flex-col gap-y-6 pb-24 item-start'>
            <input name='user_name' className='bg-white/10 border-b px-3 py-3 outline-none w-full placeholder:text-white/50 focus:border-accent transition-all' type='text' placeholder='your name' required />
            <input name='user_email' className='bg-white/10 border-b px-3 py-3 outline-none w-full placeholder:text-white/50 focus:border-accent transition-all' type='email' placeholder='your email' required />
            <textarea name='message' className='bg-white/10 border-b px-3 py-3 outline-none w-full placeholder:text-white/50 focus:border-accent transition-all' placeholder='your message' required ></textarea>
            <button type='submit' className='btn btn-lg'>send message</button>
          </form>
        </div>
      </div>
      <div>
        <SnackbarProvider />
      </div>
    </section>

  );
};

export default Contact;
