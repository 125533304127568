import React from 'react';
import ProjectGrid from './ProjectGrid.js';
import { projects } from '../data/projects.js';

const Work = () => {
  return (
    <section className='section' id='work'>
      <div className='container mx-auto'>
        <div div='flex flex-col lg:flex-row'>
          <div>
            <h2 className='text-center text-4xl font-bold mb-10'>projects</h2>
          </div>
          <div className=''>
            <ProjectGrid projects={projects} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
